import { Box } from 'native-base'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Route, Routes, useLocation } from 'react-router-dom'

import { ApolloProvider } from '@apollo/client'
import { StatsigProvider } from '@statsig/react-bindings'

import client from './apolloClient'
import AppStoreRedirect from './components/AppStoreRedirect'
import Footer from './components/Footer'
import Home from './components/Home'
import Loader from './components/Loader'
import NavBar from './components/NavBar'
import Privacy from './components/Privacy'
import ScanOrDownload from './components/ScanOrDownload'
import SearchResultsPage from './components/SearchResultsPage'
import Support from './components/Support'
import AppContextProvider from './context/AppContextProvider'
import AuthContextProvider from './context/AuthContextProvider'
import OverlayProvider from './context/OverlayContextProvider'
import { useAsyncStatsigClient } from './hooks/useAsyncStatsigClient'
import { analytics } from './utils/analytics-v2'

export default function App() {
  const location = useLocation()
  const [mQuery, setMQuery] = useState({ matches: window.innerWidth < 600 ? true : false })
  const { client: statsigClient, isLoading: loadingStatsig } = useAsyncStatsigClient()

  useEffect(() => {
    let mediaQuery = window.matchMedia('(max-width: 600px)')
    mediaQuery?.addEventListener('change', setMQuery)
    return () => mediaQuery?.removeEventListener('change', setMQuery)
  }, [])

  useEffect(() => {
    analytics.page()
  }, [location])

  return (
    <ApolloProvider client={client}>
      <AuthContextProvider>
        <StatsigProvider client={statsigClient}>
          <AppContextProvider>
            <OverlayProvider>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%',
                }}
                overflowX={isMobile ? 'hidden' : 'auto'}
              >
                <NavBar />
                {loadingStatsig ? (
                  <Loader />
                ) : (
                  <>
                    <Routes>
                      <Route path="/download-app" element={<AppStoreRedirect />} />
                      <Route path="/" element={<Home showMobile={mQuery && mQuery?.matches} />} />
                      <Route path="trackingId/:trackingId" element={<SearchResultsPage />} />
                      <Route path="barcode/:trackingId" element={<SearchResultsPage />} />
                      <Route path="privacy" element={<Privacy />} />
                    </Routes>
                    <ScanOrDownload />
                    <Box
                      style={{ display: 'flex', gap: 20, width: '100%' }}
                      flexDirection={(mQuery && mQuery?.matches) || isMobile ? 'column' : 'row'}
                      alignItems={'center'}
                      justifyContent="center"
                      py={2}
                      maxW="1550px"
                    >
                      <Support showMobile={mQuery && mQuery?.matches} maxWidth="350px" height="180px" />
                    </Box>
                  </>
                )}
                <Footer />
              </Box>
            </OverlayProvider>
          </AppContextProvider>
        </StatsigProvider>
      </AuthContextProvider>
    </ApolloProvider>
  )
}
