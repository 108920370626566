/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { trackEvent } from '../utils/analytics-v2'

const APP_STORE_URLS = {
  ios: 'https://apps.apple.com/us/app/veho-mobile/id1618482681',
  android: 'https://play.google.com/store/apps/details?id=com.veho.consumermobile&hl=en_US&gl=US&pli=1',
}

export default function AppStoreRedirect() {
  const navigate = useNavigate()

  useEffect(() => {
    const userAgent = navigator?.userAgent || navigator?.vendor || (window as any).opera
    const isAndroid = /android/i.test(userAgent)
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream

    if (isAndroid) {
      trackEvent('download_app', { element: 'button', platform: 'android' })
      window.location.href = APP_STORE_URLS.android
    } else if (isIOS) {
      trackEvent('download_app', { element: 'button', platform: 'ios' })
      window.location.href = APP_STORE_URLS.ios
    } else {
      // If not on a mobile device, redirect to home page
      navigate('/')
    }
  }, [navigate])

  return null
}
