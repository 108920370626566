import { Box, Heading, Text } from 'native-base'
import { useContext, useEffect } from 'react'

import { AppContext } from '../context/AppContextProvider'
import { OverlayState } from '../context/OverlayContextProvider'
import serviceTypes from '../enums/service-types'
import { trackEvent } from '../utils/analytics-v2'
import {
  formatAddress,
  formatDeliveredPackageInfo,
  formatNotYetDeliveredPackageInfo,
  getTitle,
} from '../utils/displayUtils'
import { getDeliveryState } from '../utils/helper'
import { Alert } from './Alert'
import { InstructionsDisplayBox } from './Instructions/InstructionsDisplayBox'

const OrderInfo = () => {
  const { order } = useContext(AppContext)
  const { toggleModal } = useContext(OverlayState)

  // Open the instructions modal on page load if the order is missing instructions
  // and the user hasn't visited the page before
  useEffect(() => {
    if (!order?.orderId) return

    // Check if the user has already opened the page
    const hasVisitedKey = `hasVisited:${order.orderId}`
    const hasVisited = localStorage.getItem(hasVisitedKey)

    if (!hasVisited && !order.hasInstructions && order?.instructionEditsAllowed) {
      // Open the modal only if the user hasn't visited before
      toggleModal({ showModal: true, auth_flow: 'instructions' })
      trackEvent('view_instructions', { trigger: 'auto' })
      localStorage.setItem(hasVisitedKey, 'true') // Mark as visited
    }
  }, [order, toggleModal])

  if (!order) {
    return null
  }

  const firstPkg = order?.packages?.[0]
  const { isException, isPreDeliveryWindowStage, isWithinDeliveryWindowStage, isPostDeliveryWindowStage } =
    getDeliveryState(order)

  const instructionsButtonOnPress = () => {
    toggleModal({ showModal: true, auth_flow: 'instructions' })
    trackEvent('view_instructions', { trigger: 'manual' })
  }

  // For now we are only displaying the first built in alert rather than using the alert code
  const hasAlert = !!order.alerts?.length

  return (
    <Box width="100%" paddingBottom="24px">
      {hasAlert && <Alert />}
      <Box>
        <Heading
          style={{
            fontSize: 20,
          }}
          textAlign="left"
          paddingTop={4}
        >
          {order.clientDisplayName || order.clientName}
        </Heading>
      </Box>
      <Box marginTop={3}>
        <Text fontFamily={'bodyBold'} fontWeight="700" style={{ fontSize: 14 }}>
          {order.serviceType === serviceTypes.PICKUP ? 'Picking up from' : 'Delivering to'}
        </Text>
      </Box>
      <Box>
        <Text fontWeight="400" style={{ fontSize: 18 }}>
          {formatAddress(order.address)}
        </Text>
      </Box>
      {isPreDeliveryWindowStage && (
        <>
          <Box marginTop={2}>
            <Text fontFamily={'bodyBold'} fontWeight="600" style={{ fontSize: 14 }}>
              {getTitle(isPreDeliveryWindowStage, false, false, order.serviceType)}
            </Text>
          </Box>
          <Box>
            <Text fontWeight="400" style={{ fontSize: 18 }}>
              {formatNotYetDeliveredPackageInfo(
                order.calculatedPackagesInformation,
                firstPkg?.timeZone ?? order?.timeZone
              )}
            </Text>
          </Box>
        </>
      )}

      {isWithinDeliveryWindowStage && (
        <>
          <Box marginTop={2}>
            <Text fontFamily={'bodyBold'} fontWeight="700" style={{ fontSize: 14 }}>
              {getTitle(false, isWithinDeliveryWindowStage, false, order.serviceType)}
            </Text>
          </Box>
          <Box>
            <Text fontWeight="400" style={{ fontSize: 18 }}>
              {formatNotYetDeliveredPackageInfo(order.calculatedPackagesInformation, order.timeZone)}
            </Text>
          </Box>
        </>
      )}

      {isPostDeliveryWindowStage && (
        <>
          <Box marginTop={2}>
            <Text fontFamily={'bodyBold'} style={{ fontSize: 14 }} fontWeight="600">
              {getTitle(false, false, isPostDeliveryWindowStage, order.serviceType)}
            </Text>
          </Box>
          <Box>
            <Text style={{ fontSize: 18 }} fontWeight="400">
              {formatDeliveredPackageInfo(order.calculatedPackagesInformation)}
            </Text>
          </Box>
        </>
      )}

      {isException && (
        <>
          <Box marginTop={2}>
            <Text fontFamily={'bodyBold'} style={{ fontSize: 14 }} fontWeight="600">
              {order?.serviceType === serviceTypes.PICKUP ? 'Pickup window' : 'Order delivery window'}
            </Text>
          </Box>
          <Box>
            <Text style={{ fontSize: 18 }} fontWeight="400">
              View order details below for more specific information
            </Text>
          </Box>
        </>
      )}

      {order.instructionEditsAllowed && (
        <Box marginTop={2}>
          <InstructionsDisplayBox order={order} onPress={instructionsButtonOnPress} />
        </Box>
      )}
    </Box>
  )
}

export default OrderInfo
