import { Modal } from 'native-base'

import { IOverlayState } from '../../context/OverlayContextProvider'
import { Order } from '../../types/graphql'
import { OrderMap, ProofOfServicePhoto } from './OrderMap'

interface IMapModalProps {
  isOpen: boolean
  onClose: () => void | undefined
  toggleMap: () => void | undefined
  order: Order
  proofOfServicePhotos?: ProofOfServicePhoto[]
  userAuthed?: boolean
  toggleModal: IOverlayState['toggleModal']
}

export const MapModal = ({
  isOpen,
  onClose,
  toggleMap,
  order,
  toggleModal,
  proofOfServicePhotos,
  userAuthed,
}: IMapModalProps) => {
  const handleAddInstructions = () => {
    toggleMap()
    toggleModal({ showModal: true, auth_flow: 'instructions' })
  }

  const handleEditAddress = () => {
    toggleMap()
    toggleModal({ showModal: true, auth_flow: 'edit_address' })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
      <OrderMap
        order={order as Order}
        proofOfServicePhotos={proofOfServicePhotos}
        userAuthed={userAuthed}
        fullScreenMap={true}
        toggleMap={toggleMap}
        orderMapStyles={{
          orderMapContainer: {
            position: 'absolute',
            height: '100%',
            zIndex: 2000,
            width: '100%',
          },
          mapContainer: {
            height: '100vh',
            width: '100wh',
            borderRadius: 0,
            overflow: 'show',
          },
        }}
        addInstructions={handleAddInstructions}
        editAddress={handleEditAddress}
      />
    </Modal>
  )
}
