import { HStack, Text, VStack } from 'native-base'
import { isMobile } from 'react-device-detect'

import { CheckRounded, ErrorOutlineRounded } from '@mui/icons-material'
import { Box } from '@mui/material'
import { CarProfile, Factory, ListChecks, Package as PackageIcon, Truck, Warehouse } from '@phosphor-icons/react'

import CaretDownSVG from '../assets/CaretDownSVG'
import WarningSVG from '../assets/WarningSVG'
import packageEvents, { errorEvents } from '../enums/package-events'
import serviceTypes from '../enums/service-types'
import { EventLog, PackageEventOperations } from '../types/graphql'
import { formatDate, useScreenSize } from '../utils/displayUtils'
import { getDelayDetailText } from '../utils/helper'

interface IPackageEventProps {
  trackingId: string
  details: EventLog
  serviceType: string
  clientDisplayName: string
  showBorder: boolean
  isMostCurrentEvent: boolean
  onClick?: () => void
  packageEventLogExpanded?: boolean
  timeZone?: string
}

export const PackageEvent = ({
  trackingId,
  details,
  serviceType,
  clientDisplayName,
  showBorder,
  isMostCurrentEvent,
  onClick,
  packageEventLogExpanded,
  timeZone,
}: IPackageEventProps) => {
  const screenSize = useScreenSize()
  const isMobileScreen = isMobile || screenSize.width < 625

  const mapEventTo = (event: PackageEventOperations, field: string, message?: string | undefined | null) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let result: any
    switch (event) {
      case packageEvents.CREATED:
        result = {
          isException: false,
          label: serviceType === serviceTypes.PICKUP ? 'Pickup scheduled' : 'Veho received order information',
          labelColor: '#665632',
          labelBackground: '#FFEFCB',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                background: '#F5F5ED',
                border: '1px solid #21211F',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <ListChecks size={24} color="#21211f" />
            </Box>
          ),
        }
        break
      case packageEvents.PICKED_UP_FROM_CLIENT:
        result = {
          isException: false,
          label: `Departed ${clientDisplayName} facility`,
          labelColor: '#665632',
          labelBackground: '#FFEFCB',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                background: '#F5F5ED',
                border: '1px solid #21211f',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <Factory size={24} color="#21211f" />
            </Box>
          ),
        }
        break
      case packageEvents.DROPPED_OFF_AT_VEHO:
        result = {
          isException: false,
          label: 'Arrived at Veho facility',
          labelColor: '#665632',
          labelBackground: '#FFEFCB',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                background: '#F5F5ED',
                border: '1px solid #21211f',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <Warehouse size={24} color="#21211f" />
            </Box>
          ),
        }
        break
      case packageEvents.PACKAGE_DEPARTED_FROM_FACILITY:
        result = {
          isException: false,
          label: 'In transit',
          labelColor: '#21211F',
          labelBackground: '#ECECE5',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                background: '#F5F5ED',
                border: '1px solid #21211f',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <Truck size={24} color="#21211f" />
            </Box>
          ),
        }
        break
      case packageEvents.PACKAGE_ARRIVED_AT_FACILITY:
        result = {
          isException: false,
          label: 'At Veho facility',
          labelColor: '#21211F',
          labelBackground: '#ECECE5',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                background: '#F5F5ED',
                border: '1px solid #21211f',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <Warehouse size={24} color="#21211f" />
            </Box>
          ),
        }
        break
      case packageEvents.PICKED_UP_FROM_VEHO:
        result = {
          isException: false,
          label: 'Out for delivery',
          labelColor: '#21211F',
          labelBackground: '#DCEBF7',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                background: '#F5F5ED',
                border: '1px solid #21211f',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <CarProfile size={24} color="#21211f" />
            </Box>
          ),
        }
        break
      case packageEvents.DELIVERED:
        result = {
          isException: false,
          label: 'Delivered',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: '#21211F',
                background: '#000000',
                border: '1px solid #21211F',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <PackageIcon size={24} color="#FFFFFF" weight="bold" />
            </Box>
          ),
        }
        break
      case packageEvents.OUT_FOR_PICKUP:
        result = {
          isException: false,
          label: 'Driver out for pickup',
          labelColor: '#21211F',
          labelBackground: '#DCEBF7',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                background: '#F5F5ED',
                border: '1px solid #21211f',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <CarProfile size={24} color="#21211f" />
            </Box>
          ),
        }
        break
      case packageEvents.PICKED_UP_FROM_CONSUMER:
        result = {
          isException: false,
          label: 'Item(s) picked up',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: '#2D5A37',
                background: '#C6F3D0',
                border: '1px solid #2D5A37',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <CheckRounded
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            </Box>
          ),
        }
        break
      case packageEvents.PICKUP_DROPPED_OFF_AT_VEHO:
        result = {
          isException: false,
          label: 'Item(s) at Veho facility',
          labelColor: '#665632',
          labelBackground: '#FFEFCB',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                background: '#F5F5ED',
                border: '1px solid #21211F',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <Warehouse size={24} color="#21211F" />
            </Box>
          ),
        }
        break
      case packageEvents.MISDELIVERED:
        result = {
          isException: true,
          label: 'Issue with order',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: '#972626',
                background: '#FDB2B2',
                border: '1px solid #972626',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <ErrorOutlineRounded
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            </Box>
          ),
          message: message?.includes('Delivered to wrong location')
            ? `We're sorry that your package was not delivered to the expected location. We are working with the driver to go back and retrieve the package. Sometimes, packages are accidentally delivered to neighbors, or other members of your household, if you would like to check with them. We do not recommend going to find the package if you have health or safety concerns about doing so. For questions, we recommend you text Veho Customer Support at ${process.env.REACT_APP_SMS_SHORTCODE}.`
            : `Unfortunately, your package has been lost in transit. We apologize for the inconvenience. Please reach out to the merchant so they may assist you with a resolution.`,
        }
        break
      case packageEvents.DISCARDED:
        result = {
          isException: true,
          label: 'Issue with order',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: '#972626',
                background: '#FDB2B2',
                border: '1px solid #972626',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <ErrorOutlineRounded
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            </Box>
          ),
          message: `Your package from ${clientDisplayName} was unable to be delivered. We have let ${clientDisplayName} know. If you have questions or need help, please text Veho Customer Support at ${process.env.REACT_APP_SMS_SHORTCODE}.`,
        }
        break
      case packageEvents.NOT_RECEIVED_FROM_CLIENT:
        result = {
          isException: true,
          label: 'Issue with order',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: '#972626',
                background: '#FDB2B2',
                border: '1px solid #972626',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <ErrorOutlineRounded
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            </Box>
          ),
          message: `We didn't receive your package from ${clientDisplayName} when we expected to. For details on the status of your order, we recommend reaching out to ${clientDisplayName}.`,
        }
        break
      case packageEvents.RETURNED_TO_CLIENT:
        result = {
          isException: true,
          label: 'Issue with order',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: '#972626',
                background: '#FDB2B2',
                border: '1px solid #972626',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <ErrorOutlineRounded
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            </Box>
          ),
          message: `Your order is being returned to ${clientDisplayName}, because you or ${clientDisplayName} requested it, or because we weren't able to deliver it. If you have questions, please reach out to ${clientDisplayName}.`,
        }
        break
      case packageEvents.RETURNED_TO_VEHO:
        result = {
          isException: true,
          label: 'Issue with order',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: '#972626',
                background: '#FDB2B2',
                border: '1px solid #972626',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <ErrorOutlineRounded
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            </Box>
          ),
          message: `Your order has been returned to a Veho warehouse due to an issue with your delivery.  We sincerely apologize for the inconvenience.  Your delivery may be attempted again today or tomorrow.  For questions, we recommend that you contact Veho Customer Support at ${process.env.REACT_APP_SMS_SHORTCODE}.`,
        }
        break
      case packageEvents.PACKAGE_HAD_DELIVERY_ISSUE:
        result = {
          isException: true,
          label: 'Package delayed',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: '#665632',
                background: '#FFEFCB',
                border: '1px solid #665632',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <WarningSVG />
            </Box>
          ),
          message: null,
        }
        break
      case packageEvents.PACKAGE_DELAYED:
        result = {
          isException: true,
          label: 'Package delayed',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: '#21211F',
                background: '#ECECE5',
                border: '1px solid #21211F',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <ErrorOutlineRounded
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            </Box>
          ),
          message: "We're sorry for the delay. We're working to get your package back on track!",
        }
        break
      case packageEvents.CANCELLED:
        const service = serviceType === serviceTypes.PICKUP ? 'pickup' : 'order'
        result = {
          isException: true,
          label: `Issue with ${service}`,
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: '#972626',
                background: '#FDB2B2',
                border: '1px solid #972626',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <ErrorOutlineRounded
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            </Box>
          ),
          message: `This ${service} has been canceled. For more details, we recommend reaching out to ${clientDisplayName}.`,
        }
        break
      case packageEvents.PICKUP_SKIPPED:
        result = {
          isException: true,
          label: 'Issue with pickup',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: '#972626',
                background: '#FDB2B2',
                border: '1px solid #972626',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <ErrorOutlineRounded
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            </Box>
          ),
          message: `We were not able to pick up your package after a pickup attempt. This may be due to access issues at your address or difficulty in locating the package. Please contact Veho Customer Support at ${process.env.REACT_APP_SMS_SHORTCODE} if you wish to reschedule your pickup.`,
        }
        break
      case packageEvents.PICKUP_FAILED:
        result = {
          isException: true,
          label: 'Issue with pickup',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: '#972626',
                background: '#FDB2B2',
                border: '1px solid #972626',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <ErrorOutlineRounded
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            </Box>
          ),
          message: `We're sorry, it looks like something went wrong with this pickup. Please text Veho Customer Support at ${process.env.REACT_APP_SMS_SHORTCODE} for live support.`,
        }
        break
      default:
        result = {
          isException: false,
          label: '',
          message: '',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: '#2D5A37',
                background: '#C6F3D0',
                border: '1px solid #2D5A37',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <CheckRounded
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            </Box>
          ),
        }
        break
    }
    return result[field]
  }

  const renderDeliveryStatusIcon = (event: PackageEventOperations, isLatestStatus: boolean) => {
    let result

    // latest status get dark icon themes except error cases
    if (isLatestStatus) {
      switch (event) {
        case packageEvents.CREATED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  color: '#21211F',
                  background: '#000000',
                  border: '1px solid #21211F',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ListChecks size={24} color="#FFFFFF" />
              </Box>
            ),
          }
          break
        case packageEvents.PICKED_UP_FROM_CLIENT:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#21211F',
                  background: '#000000',
                  border: '1px solid #21211f',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <Factory size={24} color="#FFFFFF" />
              </Box>
            ),
          }
          break
        case packageEvents.DROPPED_OFF_AT_VEHO:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#21211F',
                  background: '#000000',
                  border: '1px solid #21211f',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <Warehouse size={24} color="#FFFFFF" />
              </Box>
            ),
          }
          break
        case packageEvents.PACKAGE_DEPARTED_FROM_FACILITY:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#21211F',
                  background: '#000000',
                  border: '1px solid #21211f',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <Truck size={24} color="#FFFFFF" />
              </Box>
            ),
          }
          break
        case packageEvents.PACKAGE_ARRIVED_AT_FACILITY:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#21211F',
                  background: '#000000',
                  border: '1px solid #21211f',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <Warehouse size={24} color="#FFFFFF" />
              </Box>
            ),
          }
          break
        case packageEvents.PICKED_UP_FROM_VEHO:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#21211F',
                  background: '#000000',
                  border: '1px solid #21211f',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <CarProfile size={24} color="#FFFFFF" />
              </Box>
            ),
          }
          break
        case packageEvents.DELIVERED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#21211F',
                  background: '#000000',
                  border: '1px solid #21211F',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <PackageIcon size={24} color="#FFFFFF" weight="bold" />
              </Box>
            ),
          }
          break
        case packageEvents.OUT_FOR_PICKUP:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#21211F',
                  background: '#000000',
                  border: '1px solid #21211f',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <CarProfile size={24} color="#FFFFFF" />
              </Box>
            ),
          }
          break
        case packageEvents.PICKED_UP_FROM_CONSUMER:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#21211F',
                  background: '#000000',
                  border: '1px solid #21211F',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <CheckRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.PICKUP_DROPPED_OFF_AT_VEHO:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#21211F',
                  background: '#000000',
                  border: '1px solid #21211F',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <Warehouse size={24} color="#FFFFFF" />
              </Box>
            ),
          }
          break
        case packageEvents.MISDELIVERED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.DISCARDED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.NOT_RECEIVED_FROM_CLIENT:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.RETURNED_TO_CLIENT:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.RETURNED_TO_VEHO:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.CANCELLED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.PICKUP_SKIPPED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.PICKUP_FAILED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.PACKAGE_HAD_DELIVERY_ISSUE:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#665632',
                  background: '#FFEFCB',
                  border: '1px solid #665632',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <WarningSVG />
              </Box>
            ),
          }
          break
        case packageEvents.PACKAGE_DELAYED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#21211F',
                  background: '#ECECE5',
                  border: '1px solid #21211F',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        default:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#2D5A37',
                  background: '#C6F3D0',
                  border: '1px solid #2D5A37',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <CheckRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
      }
    }
    // if not the latest update, we render normally
    else {
      switch (event) {
        case packageEvents.CREATED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  background: '#F5F5ED',
                  border: '1px solid #21211F',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ListChecks size={24} color="#21211f" />
              </Box>
            ),
          }
          break
        case packageEvents.PICKED_UP_FROM_CLIENT:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  background: '#F5F5ED',
                  border: '1px solid #21211f',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <Factory size={24} color="#21211f" />
              </Box>
            ),
          }
          break
        case packageEvents.DROPPED_OFF_AT_VEHO:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  background: '#F5F5ED',
                  border: '1px solid #21211f',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <Warehouse size={24} color="#21211f" />
              </Box>
            ),
          }
          break
        case packageEvents.PACKAGE_DEPARTED_FROM_FACILITY:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  background: '#F5F5ED',
                  border: '1px solid #21211f',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <Truck size={24} color="#21211f" />
              </Box>
            ),
          }
          break
        case packageEvents.PACKAGE_ARRIVED_AT_FACILITY:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  background: '#F5F5ED',
                  border: '1px solid #21211f',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <Warehouse size={24} color="#21211f" />
              </Box>
            ),
          }
          break
        case packageEvents.PICKED_UP_FROM_VEHO:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  background: '#F5F5ED',
                  border: '1px solid #21211f',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <CarProfile size={24} color="#21211f" />
              </Box>
            ),
          }
          break
        case packageEvents.DELIVERED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#21211F',
                  background: '#000000',
                  border: '1px solid #21211F',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <PackageIcon size={24} color="#FFFFFF" weight="bold" />
              </Box>
            ),
          }
          break
        case packageEvents.OUT_FOR_PICKUP:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  background: '#F5F5ED',
                  border: '1px solid #21211f',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <CarProfile size={24} color="#21211f" />
              </Box>
            ),
          }
          break
        case packageEvents.PICKED_UP_FROM_CONSUMER:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#2D5A37',
                  background: '#C6F3D0',
                  border: '1px solid #2D5A37',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <CheckRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.PICKUP_DROPPED_OFF_AT_VEHO:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  background: '#F5F5ED',
                  border: '1px solid #21211F',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <Warehouse size={24} color="#21211F" />
              </Box>
            ),
          }
          break
        case packageEvents.MISDELIVERED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.DISCARDED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.NOT_RECEIVED_FROM_CLIENT:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.RETURNED_TO_CLIENT:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.RETURNED_TO_VEHO:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.CANCELLED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.PICKUP_SKIPPED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.PICKUP_FAILED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.PACKAGE_HAD_DELIVERY_ISSUE:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#665632',
                  background: '#FFEFCB',
                  border: '1px solid #665632',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <WarningSVG />
              </Box>
            ),
          }
          break
        default:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#2D5A37',
                  background: '#C6F3D0',
                  border: '1px solid #2D5A37',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <CheckRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
      }
    }
    return result['deliveryIcon']
  }

  const isExceptionEvent = (event: EventLog) => {
    return errorEvents.includes(event?.operation)
  }

  const eventsToShowFacilityInfo = [
    packageEvents.PACKAGE_ARRIVED_AT_FACILITY,
    packageEvents.PACKAGE_DEPARTED_FROM_FACILITY,
    packageEvents.PICKED_UP_FROM_VEHO,
    packageEvents.DROPPED_OFF_AT_VEHO,
  ]

  return (
    <Box
      display="flex"
      flexDirection="row"
      key={trackingId + '_' + details?.operation}
      onClick={() => (onClick ? onClick() : null)}
    >
      <Box zIndex={1}>{renderDeliveryStatusIcon(details?.operation, isMostCurrentEvent)}</Box>
      <Box
        borderLeft={showBorder ? '1px dashed #CCCCCC' : 0}
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexGrow: 1,
          marginLeft: '-20px',
          alignItems: 'center',
        }}
        paddingBottom="16px"
      >
        <Box position="relative" left="12px" paddingLeft="28px" flex={1}>
          <Box display="flex" flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
            {!isExceptionEvent(details) && (
              <Box display="flex" alignItems="flex-start" flexDirection="column" flex={1}>
                <HStack justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
                  <VStack>
                    <Box>
                      <Text fontFamily={'bodyBold'} style={{ fontSize: 14 }} fontWeight={600}>
                        {mapEventTo(details?.operation, 'label')}
                      </Text>
                    </Box>
                    <Box>
                      <Text style={{ fontSize: 14 }} fontWeight={400} marginBottom={2}>
                        {formatDate(details?.timestamp, timeZone)}
                      </Text>
                    </Box>
                  </VStack>
                  {isMostCurrentEvent && (
                    <Box pr={2}>
                      <HStack alignItems={'center'}>
                        {!isMobileScreen && <Text style={{ fontSize: 14, marginRight: 3 }}>View Package History</Text>}
                        {packageEventLogExpanded ? <CaretDownSVG transform="rotate(180)" /> : <CaretDownSVG />}
                      </HStack>
                    </Box>
                  )}
                </HStack>
                <Box>
                  {eventsToShowFacilityInfo.includes(details?.operation) &&
                    (details?.facility ? (
                      <Text fontFamily={'Matter SemiBold'} style={{ fontSize: 14 }} fontWeight={400}>
                        {details?.facility?.city}, {details?.facility?.state}
                      </Text>
                    ) : null)}
                </Box>
              </Box>
            )}
            {isExceptionEvent(details) && (
              <Box
                style={{
                  backgroundColor:
                    details?.operation === PackageEventOperations.PackageHadDeliveryIssue ||
                    PackageEventOperations.PackageDelayed
                      ? 'transparent'
                      : '#FDB2B2',
                  borderRadius: 8,
                  color: '#000000',
                  flex: 1,
                }}
                display="flex"
                alignItems="flex-start"
                flexDirection="column"
                position="relative"
              >
                <HStack justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
                  <VStack>
                    <Box>
                      <Text fontFamily={'bodyBold'} style={{ fontSize: 14 }} fontWeight={600} color="#000000">
                        {mapEventTo(details?.operation, 'label')}
                      </Text>
                    </Box>
                    <Box paddingBottom={2}>
                      <Text style={{ fontSize: 14 }} fontWeight={400} color="#000000">
                        {formatDate(details?.timestamp)}
                      </Text>
                    </Box>
                  </VStack>
                  {isMostCurrentEvent && (
                    <Box pr={2}>
                      <HStack alignItems={'center'}>
                        {!isMobileScreen && <Text style={{ fontSize: 14, marginRight: 3 }}>View Package History</Text>}
                        {packageEventLogExpanded ? <CaretDownSVG transform="rotate(180)" /> : <CaretDownSVG />}
                      </HStack>
                    </Box>
                  )}
                </HStack>
                {packageEventLogExpanded && (
                  <Box pr={3}>
                    <Text style={{ fontSize: 14 }} fontWeight={400} marginBottom={2} color="#000000">
                      {details?.operation === PackageEventOperations.PackageHadDeliveryIssue ||
                      details?.operation === PackageEventOperations.PackageDelayed
                        ? getDelayDetailText(details?.message as string)
                        : mapEventTo(details?.operation, 'message', details?.message)}
                    </Text>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
