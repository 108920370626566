import { Marker } from '@vis.gl/react-maplibre'

import ApartmentMarkerOrangeBg from '../../assets/ApartmentMarkerOrangeBg.svg'
import HouseMarkerOrangeBg from '../../assets/HouseMarkerOrangeBg.svg'
import OfficeMarkerOrangeBg from '../../assets/OfficeMarkerOrangeBg.svg'
import PackageMarkerGreenBg from '../../assets/PackageMarkerGreenBg.svg'
import PhotoMarkerOrangeBg from '../../assets/PhotoMarkerOrangeBg.svg'

export type MarkerVariants = 'house' | 'apartment' | 'business' | 'photo' | 'package'

export interface MapMarkerProps {
  variant: MarkerVariants
  position: {
    lat: number
    lng: number
  }
  onClick?: (e: L.LeafletMouseEvent) => void
}

const MapMarker = ({ variant, position, onClick }: MapMarkerProps) => {
  const mapMarkerIcon = (markerType: MarkerVariants): string => {
    switch (markerType) {
      case 'house':
        return HouseMarkerOrangeBg
      case 'apartment':
        return ApartmentMarkerOrangeBg
      case 'business':
        return OfficeMarkerOrangeBg
      case 'photo':
        return PhotoMarkerOrangeBg
      case 'package':
        return PackageMarkerGreenBg
      default:
        return HouseMarkerOrangeBg
    }
  }

  // TO DO:
  // 1. Add onClick handler for for MarkerVariant photo to open photo viewer

  return (
    <Marker
      position={{
        lat: position.lat,
        lng: position.lng,
      }}
      latitude={position.lat}
      longitude={position.lng}
      onClick={onClick}
    >
      <img src={mapMarkerIcon(variant)} alt={`map-marker-${variant}`}></img>
    </Marker>
  )
}

export default MapMarker
