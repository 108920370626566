import { gql } from 'graphql-tag'

import { addressFields } from './addressFields'

export const orderFields = gql`
  fragment OrderFields on Order {
    alerts
    address {
      ...AddressFields
    }
    authedUserOwnsOrder
    clientName
    clientDisplayName
    calculatedOrderInformation {
      orderDeliveryDates
      isDeliveryByEvening
    }
    calculatedPackagesInformation {
      deliveredPackages {
        groupedByDeliveredAtTime {
          deliveredAt
          trackingIds
        }
        total
      }
      notDeliveredPackages {
        groupedByDeliveryDate {
          deliveryDate
          trackingIds
        }
        groupedByDeliveryWindow {
          endsAt
          startsAt
          trackingIds
        }
        groupedByEveningPackages
        groupedByMissingDeliveryDate
        total
      }
      notPickedUpPackages {
        groupedByPickUpDate {
          pickUpDate
          trackingIds
        }
        groupedByPickUpWindow {
          endsAt
          startsAt
          trackingIds
        }
      }
      totalPackages
    }
    deliveryDate
    orderId
    packages {
      deliveryTimeWindow {
        endsAt
        startsAt
      }
      eventLog {
        operation
        timestamp
        facility {
          facilityId
          city
          state
        }
        message
      }
      packageId
      trackingId
      timeZone
    }
    timeZone
    serviceType
    reroutableZipCodes
    instructionEditsAllowed
    hasInstructions
    isOrderReroutable
    isOrderReroutableByConsumer
    rerouteBlockedByClient
  }
  ${addressFields}
`
