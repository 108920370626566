import { Center, Pressable, Text } from 'native-base'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'

// TODO: Remove use of MUI Box component and replace with NativeBase
import Box from '@mui/material/Box'

const ScanOrDownload = () => {
  const navigate = useNavigate()

  const handleAppDownload = () => {
    navigate('/download-app')
  }

  return (
    <Center style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {!isMobile && (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginBottom: '32px',
            marginTop: '44px',
          }}
        >
          <img
            src="/qr-removebg.png"
            alt="qrscan"
            style={{ width: '120px', height: '120px', objectFit: 'contain', backgroundColor: 'transparent' }}
          />
        </Box>
      )}
      <Box paddingTop={isMobile ? 6 : undefined}>
        <Text fontFamily="Matter Bold" fontWeight={'700'} fontSize={'md'} letterSpacing={'-0.48px'}>
          Try our Veho app!
        </Text>
      </Box>
      <Box
        style={{
          marginBottom: '32px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
        paddingX={isMobile ? 1.5 : undefined}
        fontWeight={400}
      >
        <Text fontWeight={'400'} fontSize={'sm'}>
          Track your shipments and edit delivery instructions.
        </Text>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: !isMobile ? 'row' : 'column',
          justifyContent: 'space-between',
          width: !isMobile ? '275px' : '40%',
          marginBottom: '32px',
        }}
      >
        <Pressable id="downloadApp" onPress={handleAppDownload}>
          {isMobile ? (
            <img src="/app-store.svg" alt="Download App" />
          ) : (
            <Box>
              <img src="/app-store.svg" alt="iOS App Store" />
              <img src="/play-store.svg" alt="Google Play Store" />
            </Box>
          )}
        </Pressable>
      </Box>
    </Center>
  )
}

export default ScanOrDownload
