import { extendTheme, themeTools } from 'native-base'
import { InterfaceTagProps } from 'native-base/lib/typescript/components/composites/Tag/types'

export const colors = {
  brand: {
    orange: '#CD7C2A',
    blue: '#1446B3',
    lightBlue: '#1B60F8',
    green: '#2E8C68',
    white: '#FFFFFF',
    warmWhite: '#F5F5ED',
    black: '#000000',
    softBlack: '#21211F',
    transparent: 'transparent',
    text: '#000000',
    vermillion: '#E84931',
    darkVermillion: '#8B2C1D',
    lightGrey: '#ECECE5',
    darkGrey: '#858581',
    selectedBlue: '#A7CEEB',
  },
  primary: {
    50: '#F5F5ED',
    100: '#FF9180',
    200: '#FE6E57',
    300: '#E84931',
    400: '#C7280F',
    500: '#C7280F',
    // Correct primary.600 shade below commented out to address focus behavior for border of Input component
    // 600: '#9E1600',
    600: '#f5f5ed',
    700: '#9E1600',
    800: '#7D1100',
    900: '#520B00',
  },
  secondary: {
    50: '#FFF0E0',
    100: '#FFE5C9',
    200: '#FFC991',
    300: '#FFAB53',
    400: '#E6933F',
    500: '#CD7C2A',
    600: '#AE6E2D',
    700: '#865727',
    800: '#5A3B1B',
    900: '#22170B',
  },
  tertiary: {
    50: '#DBFAEE',
    100: '#B9F3DC',
    200: '#89E3BE',
    300: '#50D3A0',
    400: '#3FAF83',
    500: '#2E8C68',
    600: '#266D52',
    700: '#1F4C3B',
    800: '#17352A',
    900: '#0F1F19',
  },
  info: {
    50: '#DBE1F1',
    100: '#DBE1F1',
    200: '#DBE1F1',
    300: '#38435C',
    400: '#38435C',
    500: '#38435C',
    600: '#38435C',
    700: '#38435C',
    800: '#293042',
    900: '#293042',
  },
  success: {
    50: '#D4F3D9',
    100: '#D4F3D9',
    200: '#D4F3D9',
    300: '#21BA43',
    400: '#21BA43',
    500: '#21BA43',
    600: '#21BA43',
    700: '#21BA43',
    800: '#298137',
    900: '#298137',
    1000: '#2D5A37',
  },
  error: {
    50: '#F5E7E7',
    100: '#F5E7E7',
    200: '#F5E7E7',
    300: '#ED4C4C',
    400: '#ED4C4C',
    500: '#ED4C4C',
    600: '#ED4C4C',
    700: '#ED4C4C',
    800: '#901717',
    900: '#901717',
  },
  danger: {
    50: '#F5E7E7',
    100: '#F5E7E7',
    200: '#F5E7E7',
    300: '#ED4C4C',
    400: '#ED4C4C',
    500: '#ED4C4C',
    600: '#ED4C4C',
    700: '#ED4C4C',
    800: '#901717',
    900: '#901717',
  },
  // same as error because we use subtle error toasts for warnings
  warning: {
    50: '#F5E7E7',
    100: '#F5E7E7',
    200: '#F5E7E7',
    300: '#ED4C4C',
    400: '#ED4C4C',
    500: '#ED4C4C',
    600: '#ED4C4C',
    700: '#ED4C4C',
    800: '#901717',
    900: '#901717',
  },
  // possibly used in tags
  warn: {
    50: '#F3F4BF',
    100: '#F3F4BF',
    200: '#F3F4BF',
    300: '#E6EA3B',
    400: '#E6EA3B',
    500: '#838612',
    600: '#838612',
    700: '#838612',
    800: '#838612',
    900: '#838612',
  },
  gray: {
    50: '#FAFAFA',
    100: '#F8F8F8',
    200: '#E8E8E8',
    300: '#CCCCCC',
    400: '#A3A3A3',
    500: '#8B8B8B',
    600: '#696969',
    700: '#3F3F46',
    800: '#27272A',
    900: '#18181B',
  },
}

const components = {
  Alert: {
    baseStyle: {
      borderRadius: 8,
    },
  },
  Container: {
    baseStyle: (props: unknown) => {
      return {
        color: themeTools.mode(colors.brand.black, colors.brand.white)(props),
        p: '6',
        w: [868, '100%', '100%', 868, 868],
        maxWidth: null,
      }
    },
  },
  Heading: {
    baseStyle: (props: unknown) => {
      return {
        color: themeTools.mode(colors.brand.black, colors.brand.white)(props),
        backgroundColor: themeTools.mode(colors.brand.white, colors.brand.black)(props),
        fontFamily: 'heading',
      }
    },
    sizes: {
      xs: {
        fontSize: '14px',
      },
      sm: {
        fontSize: '16px',
      },
      md: {
        fontSize: '20px',
      },
      lg: {
        fontSize: '24px',
      },
      xl: {
        fontSize: '30px',
      },
      '2xl': {
        fontSize: '36px',
      },
      '3xl': {
        fontSize: '48px',
      },
      '4xl': {
        fontSize: '60px',
      },
    },
  },
  FormControl: {
    baseStyle: (props: unknown) => {
      return {
        color: themeTools.mode(colors.brand.black, colors.brand.white)(props),
        backgroundColor: themeTools.mode(colors.brand.white, colors.brand.black)(props),
      }
    },
  },
  FormControlLabel: {
    baseStyle: (props: unknown) => {
      return {
        _text: {
          color: themeTools.mode('gray.600', 'gray.200')(props),
          fontWeight: '600',
          fontSize: '12px',
          fontFamily: 'Matter Regular',
        },
      }
    },
    defaultProps: {
      _text: {},
    },
  },
  FormControlHelperText: {
    baseStyle: (props: unknown) => {
      return {
        _text: {
          fontFamily: 'Matter Regular',
          color: themeTools.mode('gray.500', 'gray.200')(props),
          fontWeight: '400', //changed as per the spec
          fontSize: '14px', //changed as per the spec
          lineHeight: '150%',
        },
      }
    },
    defaultProps: {
      _text: {},
    },
  },
  Input: {
    baseStyle: (props: unknown) => {
      return {
        fontFamily: 'Matter Regular',
        px: '14px',
        py: '12px',
        borderColor: colors.brand.softBlack,
        _focus: {
          borderWidth: 2,
          borderColor: colors.brand.softBlack,
          backgroundColor: themeTools.mode(colors.brand.white, colors.brand.black)(props),
          _invalid: {
            borderWidth: 2,
          },
          placeholderTextColor: colors.brand.softBlack,
          _focus: {
            borderColor: colors.brand.softBlack,
            borderWidth: 2,
          },
        },
        _hover: {
          borderWidth: 2,
          borderColor: colors.brand.softBlack,
          backgroundColor: themeTools.mode(colors.brand.white, colors.brand.black)(props),
          _invalid: {
            borderWidth: 2,
          },
          placeholderTextColor: colors.brand.softBlack,
          _hover: {
            borderColor: colors.brand.softBlack,
            borderWidth: 2,
          },
        },
      }
    },
    defaultProps: (props: unknown) => {
      return {
        size: 'md',
        placeholderTextColor: 'gray.300',
        borderWidth: 2,
        borderColor: colors.brand.softBlack,
        // focusOutlineColor: 'transparent', // Fix webkit bug https://bugs.webkit.org/show_bug.cgi?id=20807 not fixed on Safari Version 16.0 (17614.1.25.9.10, 17614)
        // inValidOutlineColor: 'transparent', // Fix webkit bug https://bugs.webkit.org/show_bug.cgi?id=20807 not fixed on Safari Version 16.0 (17614.1.25.9.10, 17614)
      }
    },
  },
  Checkbox: {
    baseStyle: (props: unknown) => {
      return {
        borderWidth: '1px',
        borderRadius: '2px',
        borderColor: 'gray.400',
        padding: '3px',
        _checked: {
          borderWidth: '1px',
          borderColor: 'gray.400',
          backgroundColor: themeTools.mode('primary.300', colors.brand.white)(props),
        },
        _text: {
          fontFamily: 'Matter Regular',
          lineHeight: '17.5px',
          ml: 0,
        },
        _stack: {
          alignItems: 'baseline',
        },
      }
    },
    defaultProps: {
      _icon: { size: 2 },
      _text: {
        fontSize: '14px',
      },
    },
  },
  Text: {
    defaultProps: {
      size: 'body',
    },
    sizes: {
      label: {
        fontSize: '10px',
      },
      caption: {
        fontSize: '12px',
      },
      smallBody: {
        fontSize: '14px',
      },
      body: {
        fontSize: '16px',
      },
      display: {
        fontSize: '20px',
      },
      displayLarge: {
        fontSize: '24px',
      },
      displayXL: {
        fontSize: '32px',
      },
      display2XL: {
        fontSize: '40px',
      },
    },
    variants: {
      pressable: {
        fontSize: 16,
        fontWeight: '500',
        lineHeight: 16,
      },
    },
  },
  Tag: {
    baseStyle: (props: InterfaceTagProps & { theme: unknown }) => {
      const isPrimary = props.colorScheme === 'primary'
      return {
        _text: {
          _light: {
            color: isPrimary ? `${props.colorScheme}.300` : `${props.colorScheme}.500`,
          },
          fontWeight: '600',
        },
      }
    },
    sizes: {
      sm: {
        py: '0.8',
        _text: {
          fontSize: '2xs',
        },
      },
      md: {
        py: '1.5',
      },
      lg: {
        py: 2,
      },
    },
  },
  SectionList: {
    baseStyle: (props: unknown) => {
      return {
        color: themeTools.mode(colors.brand.black, colors.brand.white)(props),
        backgroundColor: themeTools.mode(colors.brand.white, colors.brand.black)(props),
      }
    },
  },
  Select: {
    baseStyle: {
      borderWidth: 2,
      borderColor: 'brand.softBlack',
      bg: 'brand.white',
    },
  },
  Slider: {
    defaultProps: {
      colorScheme: 'secondary',
    },
  },
  Spinner: {
    baseStyle: {
      color: 'secondary.300',
    },
  },
  Modal: {
    baseStyle: {
      position: 'sticky',
      top: 0,
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
  },
  Tooltip: {
    baseStyle: (props: unknown) => {
      return {
        _text: {
          textAlign: 'center',
          fontFamily: 'Matter Regular',
          fontSize: '12px',
          fontWeight: '400',
          color: colors.brand.white,
          px: 3,
        },
        bg: 'info.300',
      }
    },
    defaultProps: {
      bg: 'info.300',
    },
  },
  ModalHeader: {
    baseStyle: () => {
      return {
        px: '3',
        pt: '3',
        pb: '1',
        borderBottomWidth: '0',
        _text: {
          color: 'text.900',
          fontSize: 'md',
          fontWeight: 'semibold',
          lineHeight: 'sm',
        },
        bg: 'muted.50',
        borderColor: 'muted.800',
        _dark: {
          bg: 'muted.800',
          borderColor: 'muted.700',
          _text: {
            color: 'text.50',
          },
        },
      }
    },
  },
  Button: {
    baseStyle: {
      _disabled: {
        opacity: 20,
      },
    },
    sizes: {
      lg: {
        px: '24px',
        py: '12px',
        _text: {
          fontSize: 'sm',
        },
      },
      sm: {
        px: '16px',
        py: '4px',
        _text: {
          fontSize: 'xs',
        },
      },
    },
    variants: {
      rounded: () => {
        return {
          bg: colors.primary[300],
          _text: {
            color: colors.brand.white,
            fontWeight: 600,
            fontFamily: 'Matter Regular',
          },
          _pressed: {
            bg: colors.primary[500],
          },
          rounded: 'full',
        }
      },
      roundedSubtle: () => {
        return {
          bg: colors.info[100],
          _text: {
            color: colors.brand.black,
            fontWeight: 600,
            fontFamily: 'Matter Regular',
          },
          _pressed: {
            bg: colors.primary[100],
          },
          rounded: 'full',
        }
      },
      instructions: () => {
        return {
          size: 'sm',
          width: 'fit-content',
          height: '32px',
          borderRadius: 100,
          bgColor: colors.brand.softBlack,
          _text: { color: colors.brand.warmWhite, fontWeight: 600 },
        }
      },
    },
    defaultProps: {
      variant: 'rounded',
      size: 'lg',
    },
  },
}

const fontSizes = {
  '2xs': 10, // not part of official theme, but used in designs
  xs: 14,
  sm: 16,
  md: 20, // Display
  lg: 24,
  xl: 30,
  '2xl': 36,
  '3xl': 48,
  '4xl': 60,
  '5xl': 60, // not part of official theme
  '6xl': 60, // not part of official theme
  '7xl': 60, // not part of official theme
  '8xl': 60, // not part of official theme
  '9xl': 60, // not part of official theme
}

const fontConfig = {
  Matter: {
    100: {
      normal: 'Matter-Light',
      italic: 'Matter-LightItalic',
    },
    200: {
      normal: 'Matter-Light',
      italic: 'Matter-LightItalic',
    },
    300: {
      normal: 'Matter-Light',
      italic: 'Matter-LightItalic',
    },
    400: {
      normal: 'Matter-Regular',
      italic: 'Matter-Italic',
    },
    500: {
      normal: 'Matter-Medium',
      italic: 'Matter-MediumItalic',
    },
    600: {
      normal: 'Matter-SemiBold',
      italic: 'Matter-SemiBoldItalic',
    },
    700: {
      normal: 'Matter-Bold',
      italic: 'Matter-BoldItalic',
    },
    800: {
      normal: 'Matter-Heavy',
      italic: 'Matter-HeavyItalic',
    },
    900: {
      normal: 'Matter-Heavy',
      italic: 'Matter-HeavyItalic',
    },
  },
  Newsreader: {
    100: {
      normal: 'Newsreader-Regular',
      italic: 'Newsreader-Italic',
    },
    200: {
      normal: 'Newsreader-Regular',
      italic: 'Newsreader-Italic',
    },
    300: {
      normal: 'Newsreader-Regular',
      italic: 'Newsreader-Italic',
    },
    400: {
      normal: 'Newsreader-Regular',
      italic: 'Newsreader-Italic',
    },
    500: {
      normal: 'Newsreader-Regular',
      italic: 'Newsreader-Italic',
    },
    600: {
      normal: 'Newsreader-SemiBold',
      italic: 'Newsreader-SemiBoldItalic',
    },
    700: {
      normal: 'Newsreader-Bold',
      italic: 'Newsreader-BoldItalic',
    },
    800: {
      normal: 'Newsreader-Bold',
      italic: 'Newsreader-BoldItalic',
    },
    900: {
      normal: 'Newsreader-Bold',
      italic: 'Newsreader-BoldItalic',
    },
  },
}

const fonts = {
  headingRebrand: 'Newsreader Regular',
  heading: 'Matter Regular',
  body: 'Matter Regular',
  bodySemiBold: 'Matter SemiBold',
  bodyBold: 'Matter Bold',
  bodyLight: 'Matter Light',
}

const space = {
  1: '4px',
  2: '8px',
  3: '16px',
  4: '24px',
  5: '32px',
  6: '48px',
  7: '64px',
}

export const theme = extendTheme({
  components,
  colors,
  config: { initialColorMode: 'light' },
  fontConfig,
  fonts,
  fontSizes,
  space,
})

// 2. Get the type of the CustomTheme
type CustomThemeType = typeof theme

// 3. Extend the internal NativeBase Theme
declare module 'native-base' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ICustomTheme extends CustomThemeType {}
}
