import { Form } from 'formik'
import { VStack } from 'native-base'
import * as yup from 'yup'

import { AddressAttribute, Order, OrderServiceType } from '../../../types/graphql'
import { AddressAttributesInput, AddressAttributesInputType } from './AddressAttributesInput'
import { OtherInstructionsInput } from './OtherInstructionsInput'
import { PreferredLocationInputType } from './PreferredLocationInput'
import { PropertyTypeInputType, PropertyTypesInput } from './PropertyTypesInput'

const typeErrorString = 'This field must be a string.'
const maxLength20 = 'Must not exceed 20 characters.'
const maxLength250 = 'Must not exceed 250 characters.'

export const instructionsFormSchema = yup.object().shape({
  attributes: yup
    .array(
      yup.string().oneOf(Object.keys(AddressAttribute).map(k => AddressAttribute[k as keyof typeof AddressAttribute]))
    )
    .nullable(),
  preferredLocations: yup.string().typeError(typeErrorString).max(250, maxLength250).nullable(),
  preferredPickupLocations: yup.string().typeError(typeErrorString).max(250, maxLength250).nullable(),
  instructions: yup.string().typeError(typeErrorString).max(250, maxLength250).nullable(),
  pickupInstructions: yup.string().typeError(typeErrorString).max(250, maxLength250).nullable(),
  securityCode: yup.string().typeError(typeErrorString).max(20, maxLength20).nullable(),
})

export type InstructionsFormType = PropertyTypeInputType & PreferredLocationInputType & AddressAttributesInputType

export interface InstructionsFormProps {
  order: Order
}

export const InstructionsForm = ({ order }: InstructionsFormProps) => {
  return (
    <Form style={{ alignItems: 'stretch', width: '100%' }}>
      <VStack key={'instructions-form-stack'} space={2} alignItems={'stretch'} width={'100%'}>
        <PropertyTypesInput serviceType={order?.serviceType ?? OrderServiceType.Delivery} />
        <AddressAttributesInput />
        <OtherInstructionsInput serviceType={order?.serviceType ?? OrderServiceType.Delivery} />
      </VStack>
    </Form>
  )
}
