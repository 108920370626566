import { DateTime, Interval } from 'luxon'
import { Center, Image, Modal, Pressable, Text, View } from 'native-base'
import { useCallback, useContext, useEffect, useState } from 'react'
import React from 'react'
import { isMobile } from 'react-device-detect'

import { useLazyQuery } from '@apollo/client'
import { CheckRounded, ErrorOutlineRounded } from '@mui/icons-material'
import { Box } from '@mui/material'
import {
  CarProfile,
  Factory,
  ListChecks,
  MagnifyingGlassPlus,
  Package as PackageIcon,
  Truck,
  Warehouse,
} from '@phosphor-icons/react'

import { AppContext } from '../context/AppContextProvider'
import packageEvents, { errorEvents } from '../enums/package-events'
import serviceTypes from '../enums/service-types'
import { GET_PROOF_OF_SERVICE_URLS } from '../graphql/getProofOfServiceUrls'
import {
  EventLog,
  GetProofOfServiceUrlsQuery,
  GetProofOfServiceUrlsQueryVariables,
  Package,
  PackageEventOperations,
  ProofOfServicePhotoUrl,
} from '../types/graphql'
import { formatDate, sortedList } from '../utils/displayUtils'
import { getDelayDetailText, potentiallyOverNinetyDaysAgo } from '../utils/helper'

export default function PackageInfo() {
  const { order } = useContext(AppContext)

  const [queryProofOfServiceUrlsByPackageIds, proofOfServicePhotoUrls] = useLazyQuery<
    GetProofOfServiceUrlsQuery,
    GetProofOfServiceUrlsQueryVariables
  >(GET_PROOF_OF_SERVICE_URLS, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    const deliveredPackageIds =
      order?.packages
        .filter(p => p.eventLog[0]?.operation === PackageEventOperations.Delivered)
        // ignore lint rule as we only want to return packageIds within the interval
        // eslint-disable-next-line array-callback-return
        .filter(p => {
          const deliveredTimeStamp = p?.eventLog[0]?.timestamp
          const interval = Interval.fromDateTimes(
            DateTime.fromISO(deliveredTimeStamp).setZone((p.timeZone ?? order.timeZone) as string),
            DateTime.fromISO(deliveredTimeStamp)
              .setZone((p.timeZone ?? order.timeZone) as string)
              .plus({ days: 90, hours: 23, minutes: 59 })
          )
          const now = DateTime.now().setZone((p.timeZone ?? order.timeZone) as string)
          const todayWithinInterval = interval.contains(now)

          if (todayWithinInterval) {
            return p.packageId
          }
        }) || []
    if (deliveredPackageIds.length > 0) {
      const packageIds: string[] = deliveredPackageIds.map(p => p.packageId)
      queryProofOfServiceUrlsByPackageIds({ variables: { packageIds } })
    }
  }, [order, queryProofOfServiceUrlsByPackageIds])

  return (
    <Box style={{ borderBottom: '1px solid #21211F' }} marginBottom="-32px">
      {order?.packages.map(_packageDetails => (
        <PackageDetails
          _packageDetails={_packageDetails}
          _clientDisplayName={order.clientDisplayName}
          _serviceType={order.serviceType}
          _proofOfServicePhotoUrls={proofOfServicePhotoUrls?.data?.proofOfServicePhotoUrls}
          key={_packageDetails.trackingId}
          _proofOfServicePhotoUrlsCalled={proofOfServicePhotoUrls.data !== undefined}
        />
      ))}
    </Box>
  )
}

const PackageDetails = ({
  _packageDetails,
  _clientDisplayName,
  _serviceType,
  _proofOfServicePhotoUrls,
  _proofOfServicePhotoUrlsCalled,
}: {
  _packageDetails: Package
  _clientDisplayName: string
  _serviceType: string
  _proofOfServicePhotoUrls?: ProofOfServicePhotoUrl[]
  _proofOfServicePhotoUrlsCalled: boolean
}) => {
  const [sortedDetails, setSortedDetails] = useState<EventLog[]>([])
  const [photoModalVisible, setPhotoModalVisible] = useState<boolean>(false)

  useEffect(() => {
    if (photoModalVisible) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'visible'
    }
  }, [photoModalVisible])

  useEffect(() => {
    setSortedDetails(sortedList(_packageDetails))
  }, [_packageDetails])

  const packageUrl = _proofOfServicePhotoUrls?.find(packageUrl => packageUrl.packageId === _packageDetails.packageId)

  const mapEventTo = (event: PackageEventOperations, field: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let result: any
    switch (event) {
      case packageEvents.CREATED:
        result = {
          isException: false,
          label: _serviceType === serviceTypes.PICKUP ? 'Pickup scheduled' : 'Veho received order information',
          labelColor: '#665632',
          labelBackground: '#FFEFCB',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                background: '#F5F5ED',
                border: '1px solid #21211F',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <ListChecks size={24} color="#21211f" />
            </Box>
          ),
        }
        break
      case packageEvents.PICKED_UP_FROM_CLIENT:
        result = {
          isException: false,
          label: `Departed ${_clientDisplayName} facility`,
          labelColor: '#665632',
          labelBackground: '#FFEFCB',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                background: '#F5F5ED',
                border: '1px solid #21211f',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <Factory size={24} color="#21211f" />
            </Box>
          ),
        }
        break
      case packageEvents.DROPPED_OFF_AT_VEHO:
        result = {
          isException: false,
          label: 'Arrived at Veho facility',
          labelColor: '#665632',
          labelBackground: '#FFEFCB',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                background: '#F5F5ED',
                border: '1px solid #21211f',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <Warehouse size={24} color="#21211f" />
            </Box>
          ),
        }
        break
      case packageEvents.PACKAGE_DEPARTED_FROM_FACILITY:
        result = {
          isException: false,
          label: 'In transit',
          labelColor: '#21211F',
          labelBackground: '#ECECE5',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                background: '#F5F5ED',
                border: '1px solid #21211f',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <Truck size={24} color="#21211f" />
            </Box>
          ),
        }
        break
      case packageEvents.PACKAGE_ARRIVED_AT_FACILITY:
        result = {
          isException: false,
          label: 'At Veho facility',
          labelColor: '#21211F',
          labelBackground: '#ECECE5',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                background: '#F5F5ED',
                border: '1px solid #21211f',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <Warehouse size={24} color="#21211f" />
            </Box>
          ),
        }
        break
      case packageEvents.PICKED_UP_FROM_VEHO:
        result = {
          isException: false,
          label: 'Out for delivery',
          labelColor: '#21211F',
          labelBackground: '#DCEBF7',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                background: '#F5F5ED',
                border: '1px solid #21211f',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <CarProfile size={24} color="#21211f" />
            </Box>
          ),
        }
        break
      case packageEvents.DELIVERED:
        result = {
          isException: false,
          label: 'Delivered',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: '#21211F',
                background: '#000000',
                border: '1px solid #21211F',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <PackageIcon size={24} color="#FFFFFF" weight="bold" />
            </Box>
          ),
        }
        break
      case packageEvents.OUT_FOR_PICKUP:
        result = {
          isException: false,
          label: 'Driver out for pickup',
          labelColor: '#21211F',
          labelBackground: '#DCEBF7',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                background: '#F5F5ED',
                border: '1px solid #21211f',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <CarProfile size={24} color="#21211f" />
            </Box>
          ),
        }
        break
      case packageEvents.PICKED_UP_FROM_CONSUMER:
        result = {
          isException: false,
          label: 'Item(s) picked up',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: '#2D5A37',
                background: '#C6F3D0',
                border: '1px solid #2D5A37',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <CheckRounded
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            </Box>
          ),
        }
        break
      case packageEvents.PICKUP_DROPPED_OFF_AT_VEHO:
        result = {
          isException: false,
          label: 'Item(s) at Veho facility',
          labelColor: '#665632',
          labelBackground: '#FFEFCB',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                background: '#F5F5ED',
                border: '1px solid #21211F',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <Warehouse size={24} color="#21211F" />
            </Box>
          ),
        }
        break
      case packageEvents.MISDELIVERED:
        result = {
          isException: true,
          label: 'Issue with order',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: '#972626',
                background: '#FDB2B2',
                border: '1px solid #972626',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <ErrorOutlineRounded
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            </Box>
          ),
          message: `Unfortunately, your package has been lost in transit. We apologize for the inconvenience. Please reach out to the merchant so they may assist you with a resolution.`,
        }
        break
      case packageEvents.DISCARDED:
        result = {
          isException: true,
          label: 'Issue with order',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: '#972626',
                background: '#FDB2B2',
                border: '1px solid #972626',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <ErrorOutlineRounded
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            </Box>
          ),
          message: `Your package from ${_clientDisplayName} was unable to be delivered. We have let ${_clientDisplayName} know. If you have questions or need help, please text Veho Customer Support at ${process.env.REACT_APP_SMS_SHORTCODE}.`,
        }
        break
      case packageEvents.NOT_RECEIVED_FROM_CLIENT:
        result = {
          isException: true,
          label: 'Issue with order',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: '#972626',
                background: '#FDB2B2',
                border: '1px solid #972626',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <ErrorOutlineRounded
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            </Box>
          ),
          message: `We didn't receive your package from ${_clientDisplayName} when we expected to. For details on the status of your order, we recommend reaching out to ${_clientDisplayName}.`,
        }
        break
      case packageEvents.RETURNED_TO_CLIENT:
        result = {
          isException: true,
          label: 'Issue with order',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: '#972626',
                background: '#FDB2B2',
                border: '1px solid #972626',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <ErrorOutlineRounded
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            </Box>
          ),
          message: `Your order is being returned to ${_clientDisplayName}, because you or ${_clientDisplayName} requested it, or because we weren't able to deliver it. If you have questions, please reach out to ${_clientDisplayName}.`,
        }
        break
      case packageEvents.RETURNED_TO_VEHO:
        result = {
          isException: true,
          label: 'Issue with order',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: '#972626',
                background: '#FDB2B2',
                border: '1px solid #972626',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <ErrorOutlineRounded
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            </Box>
          ),
          message: `Your order has been returned to a Veho warehouse due to an issue with your delivery.  We sincerely apologize for the inconvenience.  Your delivery may be attempted again today or tomorrow.  For questions, we recommend that you contact Veho Customer Support at ${process.env.REACT_APP_SMS_SHORTCODE}.`,
        }
        break
      case packageEvents.PACKAGE_HAD_DELIVERY_ISSUE:
        result = {
          isException: true,
          label: 'Package delayed',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: '#972626',
                background: '#FDB2B2',
                border: '1px solid #972626',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <ErrorOutlineRounded
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            </Box>
          ),
          message: null,
        }
        break
      case packageEvents.PACKAGE_DELAYED:
        result = {
          isException: true,
          label: 'Package delayed',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: '#21211F',
                background: '#ECECE5',
                border: '1px solid #21211F',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <ErrorOutlineRounded
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            </Box>
          ),
          message: "We're sorry for the delay. We're working to get your package back on track!",
        }
        break
      case packageEvents.CANCELLED:
        const service = _serviceType === serviceTypes.PICKUP ? 'pickup' : 'order'
        result = {
          isException: true,
          label: `Issue with ${service}`,
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: '#972626',
                background: '#FDB2B2',
                border: '1px solid #972626',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <ErrorOutlineRounded
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            </Box>
          ),
          message: `This ${service} has been canceled. For more details, we recommend reaching out to ${_clientDisplayName}.`,
        }
        break
      case packageEvents.PICKUP_SKIPPED:
        result = {
          isException: true,
          label: 'Issue with pickup',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: '#972626',
                background: '#FDB2B2',
                border: '1px solid #972626',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <ErrorOutlineRounded
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            </Box>
          ),
          message: `We were not able to pick up your package after a pickup attempt. This may be due to access issues at your address or difficulty in locating the package. Please contact Veho Customer Support at ${process.env.REACT_APP_SMS_SHORTCODE} if you wish to reschedule your pickup.`,
        }
        break
      case packageEvents.PICKUP_FAILED:
        result = {
          isException: true,
          label: 'Issue with pickup',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: '#972626',
                background: '#FDB2B2',
                border: '1px solid #972626',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <ErrorOutlineRounded
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            </Box>
          ),
          message: `We're sorry, it looks like something went wrong with this pickup. Please text Veho Customer Support at ${process.env.REACT_APP_SMS_SHORTCODE} for live support.`,
        }
        break
      default:
        result = {
          isException: false,
          label: '',
          message: '',
          deliveryIcon: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                color: '#2D5A37',
                background: '#C6F3D0',
                border: '1px solid #2D5A37',
                borderRadius: 20,
                width: 40,
                height: 40,
                boxSizing: 'border-box',
              }}
            >
              <CheckRounded
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            </Box>
          ),
        }
        break
    }
    return result[field]
  }

  const renderDeliveryStatusIcon = (event: PackageEventOperations, isLatestStatus: boolean) => {
    let result

    // latest status get dark icon themes except error cases
    if (isLatestStatus) {
      switch (event) {
        case packageEvents.CREATED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  color: '#21211F',
                  background: '#000000',
                  border: '1px solid #21211F',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ListChecks size={24} color="#FFFFFF" />
              </Box>
            ),
          }
          break
        case packageEvents.PICKED_UP_FROM_CLIENT:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#21211F',
                  background: '#000000',
                  border: '1px solid #21211f',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <Factory size={24} color="#FFFFFF" />
              </Box>
            ),
          }
          break
        case packageEvents.DROPPED_OFF_AT_VEHO:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#21211F',
                  background: '#000000',
                  border: '1px solid #21211f',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <Warehouse size={24} color="#FFFFFF" />
              </Box>
            ),
          }
          break
        case packageEvents.PACKAGE_DEPARTED_FROM_FACILITY:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#21211F',
                  background: '#000000',
                  border: '1px solid #21211f',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <Truck size={24} color="#FFFFFF" />
              </Box>
            ),
          }
          break
        case packageEvents.PACKAGE_ARRIVED_AT_FACILITY:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#21211F',
                  background: '#000000',
                  border: '1px solid #21211f',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <Warehouse size={24} color="#FFFFFF" />
              </Box>
            ),
          }
          break
        case packageEvents.PICKED_UP_FROM_VEHO:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#21211F',
                  background: '#000000',
                  border: '1px solid #21211f',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <CarProfile size={24} color="#FFFFFF" />
              </Box>
            ),
          }
          break
        case packageEvents.DELIVERED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#21211F',
                  background: '#000000',
                  border: '1px solid #21211F',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <PackageIcon size={24} color="#FFFFFF" weight="bold" />
              </Box>
            ),
          }
          break
        case packageEvents.OUT_FOR_PICKUP:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#21211F',
                  background: '#000000',
                  border: '1px solid #21211f',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <CarProfile size={24} color="#FFFFFF" />
              </Box>
            ),
          }
          break
        case packageEvents.PICKED_UP_FROM_CONSUMER:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#21211F',
                  background: '#000000',
                  border: '1px solid #21211F',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <CheckRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.PICKUP_DROPPED_OFF_AT_VEHO:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#21211F',
                  background: '#000000',
                  border: '1px solid #21211F',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <Warehouse size={24} color="#FFFFFF" />
              </Box>
            ),
          }
          break
        case packageEvents.MISDELIVERED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.DISCARDED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.NOT_RECEIVED_FROM_CLIENT:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.RETURNED_TO_CLIENT:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.RETURNED_TO_VEHO:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.CANCELLED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.PICKUP_SKIPPED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.PICKUP_FAILED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.PACKAGE_HAD_DELIVERY_ISSUE:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.PACKAGE_DELAYED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#21211F',
                  background: '#ECECE5',
                  border: '1px solid #21211F',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        default:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#2D5A37',
                  background: '#C6F3D0',
                  border: '1px solid #2D5A37',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <CheckRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
      }
    }
    // if not the latest update, we render normally
    else {
      switch (event) {
        case packageEvents.CREATED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  background: '#F5F5ED',
                  border: '1px solid #21211F',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ListChecks size={24} color="#21211f" />
              </Box>
            ),
          }
          break
        case packageEvents.PICKED_UP_FROM_CLIENT:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  background: '#F5F5ED',
                  border: '1px solid #21211f',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <Factory size={24} color="#21211f" />
              </Box>
            ),
          }
          break
        case packageEvents.DROPPED_OFF_AT_VEHO:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  background: '#F5F5ED',
                  border: '1px solid #21211f',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <Warehouse size={24} color="#21211f" />
              </Box>
            ),
          }
          break
        case packageEvents.PACKAGE_DEPARTED_FROM_FACILITY:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  background: '#F5F5ED',
                  border: '1px solid #21211f',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <Truck size={24} color="#21211f" />
              </Box>
            ),
          }
          break
        case packageEvents.PACKAGE_ARRIVED_AT_FACILITY:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  background: '#F5F5ED',
                  border: '1px solid #21211f',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <Warehouse size={24} color="#21211f" />
              </Box>
            ),
          }
          break
        case packageEvents.PICKED_UP_FROM_VEHO:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  background: '#F5F5ED',
                  border: '1px solid #21211f',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <CarProfile size={24} color="#21211f" />
              </Box>
            ),
          }
          break
        case packageEvents.DELIVERED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#21211F',
                  background: '#000000',
                  border: '1px solid #21211F',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <PackageIcon size={24} color="#FFFFFF" weight="bold" />
              </Box>
            ),
          }
          break
        case packageEvents.OUT_FOR_PICKUP:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  background: '#F5F5ED',
                  border: '1px solid #21211f',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <CarProfile size={24} color="#21211f" />
              </Box>
            ),
          }
          break
        case packageEvents.PICKED_UP_FROM_CONSUMER:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#2D5A37',
                  background: '#C6F3D0',
                  border: '1px solid #2D5A37',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <CheckRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.PICKUP_DROPPED_OFF_AT_VEHO:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  background: '#F5F5ED',
                  border: '1px solid #21211F',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <Warehouse size={24} color="#21211F" />
              </Box>
            ),
          }
          break
        case packageEvents.MISDELIVERED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.DISCARDED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.NOT_RECEIVED_FROM_CLIENT:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.RETURNED_TO_CLIENT:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.RETURNED_TO_VEHO:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.CANCELLED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.PICKUP_SKIPPED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.PICKUP_FAILED:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        case packageEvents.PACKAGE_HAD_DELIVERY_ISSUE:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#972626',
                  background: '#FDB2B2',
                  border: '1px solid #972626',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <ErrorOutlineRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
        default:
          result = {
            deliveryIcon: (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: '#2D5A37',
                  background: '#C6F3D0',
                  border: '1px solid #2D5A37',
                  borderRadius: 20,
                  width: 40,
                  height: 40,
                  boxSizing: 'border-box',
                }}
              >
                <CheckRounded
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Box>
            ),
          }
          break
      }
    }
    return result['deliveryIcon']
  }

  const isSuccess = () => {
    return (
      mapEventTo(sortedDetails[0].operation, 'label') === 'Delivered' ||
      mapEventTo(sortedDetails[0].operation, 'label') === 'Item(s) picked up'
    )
  }
  const isExceptionPackage = () => {
    return ['Issue with order', 'Issue with pickup', 'Package delayed'].includes(
      mapEventTo(sortedDetails[0].operation, 'label')
    )
  }
  const isExceptionEvent = (event: EventLog) => {
    return errorEvents.includes(event.operation)
  }
  const getTextStyle = (packageStatus: PackageEventOperations) => {
    if (isSuccess()) {
      return {
        backgroundColor: '#C6F3D0',
        color: '#2D5A37',
      }
    } else if (isExceptionPackage()) {
      return packageStatus === PackageEventOperations.PackageDelayed
        ? {
            backgroundColor: '#ECECE5',
            color: '#21211F',
          }
        : {
            backgroundColor: '#FDB2B2',
            color: '#972626',
          }
    } else if (mapEventTo(packageStatus, 'labelColor')) {
      return {
        backgroundColor: mapEventTo(packageStatus, 'labelBackground'),
        color: mapEventTo(packageStatus, 'labelColor'),
      }
    } else {
      return {
        backgroundColor: 'whitesmoke',
        color: '#000000',
      }
    }
  }

  const isDeliveryDateNinetyDaysOrOlder = useCallback((deliveryDate: string) => {
    return potentiallyOverNinetyDaysAgo(deliveryDate)
  }, [])
  const eventsToShowFacilityInfo = [
    packageEvents.PACKAGE_ARRIVED_AT_FACILITY,
    packageEvents.PACKAGE_DEPARTED_FROM_FACILITY,
    packageEvents.PICKED_UP_FROM_VEHO,
    packageEvents.DROPPED_OFF_AT_VEHO,
  ]

  return (
    <>
      <Center>
        <Modal
          backgroundColor="rgba(0, 0, 0, 0.0)"
          isOpen={photoModalVisible}
          onClose={() => setPhotoModalVisible(false)}
          avoidKeyboard
        >
          <Modal.Content backgroundColor="rgba(0, 0, 0, 0.0)" style={{ shadowOpacity: 0 }} width={'100%'}>
            <Modal.Body>
              <Image
                src={packageUrl?.url as string}
                borderRadius="5px"
                width={undefined}
                height={'100%'}
                style={{ aspectRatio: 3 / 4 }}
                alt={'Proof of Service Photo'}
              />
              <Pressable
                onPress={() => setPhotoModalVisible(!photoModalVisible)}
                style={{ position: 'absolute', top: 35, right: 33 }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none">
                  <rect width={32} height={32} fill="#21211F" rx={16} />
                  <path
                    fill="#fff"
                    d="M21.03 19.97a.751.751 0 0 1-1.062 1.062L16 17.062l-3.97 3.969a.751.751 0 1 1-1.062-1.063L14.938 16l-3.969-3.97a.752.752 0 0 1 1.063-1.062L16 14.938l3.97-3.97a.751.751 0 1 1 1.062 1.062L17.062 16l3.969 3.97Z"
                  />
                </svg>
              </Pressable>
            </Modal.Body>
          </Modal.Content>
        </Modal>
      </Center>
      <Box key={_packageDetails.packageId}>
        <Box
          display="flex"
          flexDirection="row"
          key={_packageDetails.trackingId}
          borderTop="1px solid #21211F"
          paddingY={'24px'}
        >
          <Box marginRight="auto">
            {sortedDetails.length > 0 && (
              <Box
                style={getTextStyle(sortedDetails[0]?.operation)}
                textAlign="center"
                paddingX="4px"
                paddingBottom="2px"
                marginBottom="4px"
                borderRadius="4px"
              >
                <Text
                  style={getTextStyle(sortedDetails[0]?.operation)}
                  textTransform={'uppercase'}
                  fontSize={'10px'}
                  fontFamily={'bodyBold'}
                >
                  {mapEventTo(sortedDetails[0]?.operation, 'label') === 'Item(s) picked up'
                    ? 'Picked Up' //show as picked up
                    : mapEventTo(sortedDetails[0]?.operation, 'label')}
                </Text>
              </Box>
            )}
            <Text style={{ fontSize: 16, fontWeight: '600' }}>{_packageDetails.trackingId}</Text>
          </Box>
        </Box>
        {/* TODO: Refactor into smaller component for better code clarity */}
        {sortedDetails.map((details, index) => (
          <Box display="flex" flexDirection="row" key={_packageDetails.trackingId + '_' + details.operation}>
            <Box zIndex={1}>{renderDeliveryStatusIcon(details.operation, 0 === index)}</Box>
            <Box
              borderLeft={sortedDetails.length === 1 || sortedDetails.length - 1 === index ? 0 : '1px dashed #CCCCCC'}
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexGrow: 1,
                marginLeft: '-20px',
                alignItems: 'center',
              }}
              paddingBottom="26px"
            >
              <Box position="relative" left="8px" paddingLeft="28px">
                <Box>
                  {!isExceptionEvent(details) && (
                    <Box display="flex" alignItems="flex-start" flexDirection="column">
                      <Box>
                        <Text fontFamily={'bodyBold'} style={{ fontSize: 14 }} fontWeight={600}>
                          {mapEventTo(details.operation, 'label')}
                        </Text>
                      </Box>
                      <Box>
                        <Text style={{ fontSize: 14 }} fontWeight={400} marginBottom={2}>
                          {formatDate(details.timestamp)}
                        </Text>
                      </Box>
                      <Box>
                        {eventsToShowFacilityInfo.includes(details?.operation) &&
                          (details?.facility ? (
                            <Text fontFamily={'Matter SemiBold'} style={{ fontSize: 14 }} fontWeight={400}>
                              {details.facility?.city}, {details.facility?.state}
                            </Text>
                          ) : null)}
                      </Box>
                      {
                        // did we get the delivery operation / event?
                        details.operation === PackageEventOperations.Delivered ? (
                          // we know it is delivered, did we get data back from our delivery photos call?
                          _proofOfServicePhotoUrlsCalled ? (
                            // did we get a package URL from that call by package ID?
                            packageUrl?.url ? (
                              <Pressable onPress={() => setPhotoModalVisible(!photoModalVisible)}>
                                <Box>
                                  <Image
                                    src={packageUrl.url}
                                    size="sm"
                                    mt={3}
                                    borderRadius="4px"
                                    width={255}
                                    height={255}
                                    resizeMode={'cover'}
                                    style={{ overflow: 'hidden' }}
                                    alt={'Proof of Service Photo'}
                                  />
                                  <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{
                                      color: '#21211F',
                                      background: '#000000',
                                      border: '1px solid #21211F',
                                      borderRadius: 20,
                                      width: 30,
                                      height: 30,
                                      boxSizing: 'border-box',
                                      position: 'absolute',
                                      top: '30px',
                                      right: '10px',
                                    }}
                                  >
                                    <MagnifyingGlassPlus size={15} color="#FFFFFF" weight="bold" />
                                  </Box>
                                </Box>
                              </Pressable>
                            ) : index === 0 ? (
                              !isDeliveryDateNinetyDaysOrOlder(details?.timestamp) ? (
                                <View mt={2}>
                                  <img src="/pendingDeliveryIcon.svg" alt="delivery icon" />
                                </View>
                              ) : null
                            ) : (
                              <View />
                            )
                          ) : (
                            <View />
                          )
                        ) : (
                          <View />
                        )
                      }
                    </Box>
                  )}
                  {isExceptionEvent(details) && (
                    <Box
                      style={{
                        backgroundColor:
                          details.operation === PackageEventOperations.PackageHadDeliveryIssue ||
                          PackageEventOperations.PackageDelayed
                            ? 'transparent'
                            : '#FDB2B2',
                        borderRadius: 8,
                        color: '#000000',
                        padding: 16,
                      }}
                      maxWidth={isMobile ? '98%' : 500}
                      display="flex"
                      alignItems="flex-start"
                      flexDirection="column"
                      position="relative"
                    >
                      <Box>
                        <Text fontFamily={'bodyBold'} style={{ fontSize: 14 }} fontWeight={600} color="#000000">
                          {mapEventTo(details.operation, 'label')}
                        </Text>
                      </Box>
                      <Box paddingBottom={2}>
                        <Text style={{ fontSize: 14 }} fontWeight={400} color="#000000">
                          {formatDate(details.timestamp)}
                        </Text>
                      </Box>
                      <Box>
                        <Text style={{ fontSize: 14 }} fontWeight={400} marginBottom={2} color="#000000">
                          {details.operation === PackageEventOperations.PackageHadDeliveryIssue ||
                          details.operation === PackageEventOperations.PackageDelayed
                            ? getDelayDetailText(details?.message as string)
                            : mapEventTo(details.operation, 'message')}
                        </Text>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  )
}
